<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card elevation="3" color class="px-3 pa-md-12">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn icon class="ma-4" color="red" dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>

          <v-form @submit.prevent="add.fetch()" v-model="valid">
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  filled
                  v-model="newtask.title"
                  label="Task Title"
                  required
                  :rules="titleRules"
                ></v-text-field>

                <v-switch
                  v-model="newtask.bool"
                  prepend-icon="mdi-alert"
                  color="error"
                  inset
                  label="Urgent"
                ></v-switch>
                <!-- <v-select
                  v-model="newtask.users_permissions_user"
                  color="error"
                  inset
                  :items="usernames"
                ></v-select> -->
                <v-select
                  :items="uss"
                  v-model="model"
                  item-text="username"
                  item-value="id"
                  label="Select"
                  @input="newtask.users_permissions_user = model.id"
                  persistent-hint
                  return-object
                  single-line
                ></v-select>
                {{ newtask.id }}
              </v-col>
              <v-col>
                <v-textarea
                  filled
                  v-model="newtask.content"
                  label="Task Description"
                  hint="What to do?"
                >
                </v-textarea>
              </v-col>
              <v-card-actions>
                <v-btn type="submit">Submit</v-btn>
              </v-card-actions>
            </v-row>
          </v-form>
        </v-card>
        <v-alert v-if="result" type="success" :value="true">
          {{ result }}
        </v-alert>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["dialog"],
  data() {
    return {
      valid: false,

      usernames: [],
      ids: [],
      uss: [],
      model: [],
      titleRules: [(v) => !!v || "Title is required"],
      content: "",
      priority: 0,
      newtask: { title: "", content: "", bool: false },
      result: "",
    };
  },
  created() {
    this.usernam();
  },
  methods: {
    usernam: function () {
      this.$axios(this.$fiturl + "users").then((r) => {
        this.uss = r.data;
        for (const i of this.uss) {
          this.ids.push(`${i.id}`);
          this.usernames.push(`${i.username}`);
        }
      });
    },
  },
  chimera: {
    add() {
      return {
        method: "post",
        params: this.newtask,
        url: this.$fiturl + "articles",
        on: {
          success() {
            this.result = "Task Successfully Added ";
          },
        },
      };
    },
  },
};
</script>

<style>
</style>