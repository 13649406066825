import Vue from "vue";
import axios from "axios";
Vue.prototype.$axios = axios.create();
Vue.prototype.$fitaxios = axios.create({
  baseURL: "https://sabz.herokuapp.com/https://fitech.herokuapp.com/articles",
});
Vue.prototype.$fiturl =
  "https://sabz.herokuapp.com/https://fitech.herokuapp.com/";
Vue.prototype.$urllog = "https://fitech.herokuapp.com/connect/google";
Vue.prototype.$urlroot = "https://fitech.herokuapp.com";
