<template>
  <v-app>
    <v-app-bar app>
      <v-fab-transition>
        <v-btn
          v-show="!hidden"
          @click="dialog = !dialog"
          color="pink"
          fab
          dark
          small
          absolute
          right
          bottom
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-toolbar-items>
        <v-btn :to="{ name: 'All' }" depressed>All Tasks</v-btn>
        <v-btn :to="{ name: 'Register' }" depressed>Register</v-btn>
        <v-btn depressed>Page 3</v-btn>
        <v-btn depressed>Page 4</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <Sendnew :dialog="dialog"></Sendnew>
    <slot></slot>
  </v-app>
</template>
<script>
import Sendnew from "../globals/sendnew";
export default {
  components: { Sendnew },
  data() {
    return {
      dialog: false,
      hidden: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.hidden = false;
    }, 1000);
  },
};
</script>

<style></style>
