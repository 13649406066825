<template>
  <Topnav>
    <v-main>
      <v-container fluid>
        <keep-alive>
          <router-view :jwt="jwt"></router-view>
        </keep-alive>
      </v-container>
    </v-main>
  </Topnav>
</template>

<script>
import Topnav from "./components/globals/topnav.vue";
import { EventBus } from "./event-bus";
export default {
  components: { Topnav },
  name: "App",

  data: () => ({
    jwt: "",
  }),
  created() {
    EventBus.$on("jw", (e) => {
      this.jwt = e;
    });
  },
};
</script>
<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>
