export const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/Home"),
  },
  {
    path: "/alltasks",
    name: "All",
    component: () => import("../pages/alltasks"),
  },
  {
    path: "/redirect",
    name: "Redirect",
    component: () => import("../components/register/redirect"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../components/register"),
  },
];
