import Vue from "vue";

export const EventBus = new Vue({
  methods: {
    change(event) {
      this.$emit("cl", event);
    },
    signing(ev) {
      this.$emit("jw", ev);
    },
  },
});
